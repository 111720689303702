<template>
  <div
    class="modal right fade"
    id="modalAddMember"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Anggota</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group">
              <label for="inputAddress2">Level di Project : </label>
              <Select2
                v-model="levelProject"
                :options="optionLevel"
                placeholder="Pilih Level di Project"
                :settings="{ settingOption: value, settingOption: value }"
                @change="myChangeEvent($event)"
                @select="mySelectEvent($event)"
              />
            </div>
            <hr />
            <div class="d-flex mb-3 justify-content-between">
              <input
                type="text"
                class="form-control"
                id="inputAddress2"
                placeholder="Masukkan Nama"
                style="max-width: 200px"
              />

              <Select2
                v-model="position"
                :options="optionPostion"
                placeholder="Pilih Jabatan"
                :settings="{ settingOption: value, settingOption: value }"
                style="width: 150px; max-width: 200px"
                @change="myChangeEvent($event)"
                @select="mySelectEvent($event)"
              />

              <Select2
                v-model="Departement"
                :options="optionDepartement"
                placeholder="Pilih Departemen"
                style="width: 200px; max-width: 300px"
                :settings="{ settingOption: value, settingOption: value }"
                @change="myChangeEvent($event)"
                @select="mySelectEvent($event)"
              />

              <div class="green-bedge" style="width: 50px; max-width: 100px">
                <img src="/img/icon-search.svg" />
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th style="width: 10%" class="text-center">
                      <label class="" style="margin-bottom: 0px">
                        <input type="checkbox" name="checkbox" />
                      </label>
                    </th>
                    <th style="width: 20%">Nama</th>
                    <th style="width: 30%">Jabatan</th>
                    <th style="width: 30%">Departemen</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">
                      <label class="" style="margin-bottom: 0px">
                        <input type="checkbox" name="checkbox" />
                      </label>
                    </td>
                    <td>Osama Kurniawan</td>
                    <td>Marketing</td>
                    <td>Audiovisual</td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <label class="" style="margin-bottom: 0px">
                        <input type="checkbox" name="checkbox" />
                      </label>
                    </td>
                    <td>Osama Kurniawan</td>
                    <td>Marketing</td>
                    <td>Audiovisual</td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <label class="" style="margin-bottom: 0px">
                        <input type="checkbox" name="checkbox" />
                      </label>
                    </td>
                    <td>Osama Kurniawan</td>
                    <td>Marketing</td>
                    <td>Audiovisual</td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <label class="" style="margin-bottom: 0px">
                        <input type="checkbox" name="checkbox" />
                      </label>
                    </td>
                    <td>Osama Kurniawan</td>
                    <td>Marketing</td>
                    <td>Audiovisual</td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <label class="" style="margin-bottom: 0px">
                        <input type="checkbox" name="checkbox" />
                      </label>
                    </td>
                    <td>Osama Kurniawan</td>
                    <td>Marketing</td>
                    <td>Audiovisual</td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <label class="" style="margin-bottom: 0px">
                        <input type="checkbox" name="checkbox" />
                      </label>
                    </td>
                    <td>Osama Kurniawan</td>
                    <td>Marketing</td>
                    <td>Audiovisual</td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <label class="" style="margin-bottom: 0px">
                        <input type="checkbox" name="checkbox" />
                      </label>
                    </td>
                    <td>Osama Kurniawan</td>
                    <td>Marketing</td>
                    <td>Audiovisual</td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <label class="" style="margin-bottom: 0px">
                        <input type="checkbox" name="checkbox" />
                      </label>
                    </td>
                    <td>Osama Kurniawan</td>
                    <td>Marketing</td>
                    <td>Audiovisual</td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <label class="" style="margin-bottom: 0px">
                        <input type="checkbox" name="checkbox" />
                      </label>
                    </td>
                    <td>Osama Kurniawan</td>
                    <td>Marketing</td>
                    <td>Audiovisual</td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <label class="" style="margin-bottom: 0px">
                        <input type="checkbox" name="checkbox" />
                      </label>
                    </td>
                    <td>Osama Kurniawan</td>
                    <td>Marketing</td>
                    <td>Audiovisual</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn-save" style="width: 100px; font-size: 14px">
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="">
            <h4 class="title">Edit Project</h4>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/data-project">Project </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Edit</li>
              </ol>
            </nav>
          </div>
          <div>
            <button class="btn btn-save">Simpan</button>
          </div>
        </div>
        <div class="card info-detail">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <router-link to="/data-project/detail" class="nav-link"
                >Detail
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                to="/data-project/detail/anggota"
                class="nav-link active"
                >Anggota</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/data-project/detail/RAB" class="nav-link"
                >RAB</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/data-project/detail/laporan" class="nav-link"
                >Laporan</router-link
              >
            </li>
            <li class="nav-item mr-auto">
              <router-link to="/data-project/detail/pembayaran" class="nav-link"
                >Pembayaran</router-link
              >
            </li>
          </ul>

          <div class="row mt-5">
            <div class="col-6">
              <div class="title">Detail Anggota Project</div>
              <div class="sub-title">Edit informasi yang diperlukan</div>
            </div>
            <div class="col-6 align-self-center text-right">
              <div>
                <button
                  class="btn-add"
                  data-toggle="modal"
                  data-target="#modalAddMember"
                >
                  + Tambah Anggota
                </button>
              </div>
            </div>
          </div>
          <hr />
          <div class="table-responsive">
            <table class="table table-bordered table-striped" id="tableAnggota">
              <thead>
                <tr>
                  <th style="width: 15%">Nama</th>
                  <th style="width: 20%">Level di Project</th>
                  <th style="width: 15%">Departemen</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Osama Kurniawan</td>
                  <td>Sound Man</td>
                  <td>Senin, 08.00 - 17.00</td>
                </tr>
                <tr>
                  <td>Osama Kurniawan</td>
                  <td>Sound Man</td>
                  <td>Senin, 08.00 - 17.00</td>
                </tr>
                <tr>
                  <td>Osama Kurniawan</td>
                  <td>Sound Man</td>
                  <td>Senin, 08.00 - 17.00</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
// import Datepicker from 'vue3-date-time-picker';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { ref } from "vue";
import $ from "jquery";

export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    // Datepicker
  },
  data() {
    return {
      myValue: "aaaa",
      statusCompany: ["Aktif", "Tidak Aktif"], // or [{id: key, text: value}, {id: key, text: value}]
      optionLevel: ["Office Marketing", "Marketing"],
      optionPosition: ["Staff", "Manager", "Marketing"],
      optionDepartement: ["Audiovisual", "ABCD"],
      placeholder: [
        {
          text: "kecamatan",
        },
      ],
      gmapsLoader: "",
      gMaps: "",
    };
  },

  mounted() {
    $("#tableAnggota").DataTable({
      info: false,
    });
    this.tooltip();
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  methods: {
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({
        id,
        text,
      });
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
  border-radius: 5px;
  border: none;
}

.btn-add {
  padding: 10px 10px;
  margin-left: 20px;
}

.nav-pills .nav-item .nav-link.active[data-v-161fae0a]:after {
  border-radius: 5px !important;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.modal-dialog {
  max-width: 800px;
}

.modal-content {
  height: 100%;
  border-radius: 0px;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 1px solid #d0d0d0;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #fff;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}

input[type="checkbox"]:checked {
  background-color: #68caf8!important;
  border: none;
}

.modal-content {
  overflow-y: auto;
}

.modal-footer {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  padding-right: 40px;
}

.modal-body {
  padding-bottom: 60px;
}
</style>
